body {
  background-color: rgb(250, 249, 247);
}

.tangerine-insight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #888; /* Lighter gray color */
}

.tangerine-insight span {
  font-size: 14px;
  margin-right: 10px;
}

.tangerine-insight .logo {
  height: calc(1.5 * 14px); /* 150% of the font size */
}

.review-compare {
  text-align: center;
  padding: 0px;
}

.image-view {
  position: relative;
  /* Set position relative for absolute positioning of children */
  width: 250px;
  height: 250px;
  margin: 5px auto;
  background-color:rgb(250, 249, 247); /* Match the webpage's background color */
  border: 3px solid transparent;
  box-sizing: border-box;
}

.image-view img {
  width: 100%;
  height: 100%;
  /* Ensure the image covers the full height of its container but isn't cut off */
  object-fit: contain;
  /* Cover the area without distorting the aspect ratio */
  object-position: center;
  /* Center the image within the container */
}

.image-caption {
  position: absolute;
  bottom: 25%;
  /* Position the caption 25% up from the bottom */
  width: 100%;
  /* Make the caption span the full width of the image-view */
  background-color: rgba(0, 0, 0, 0.5);
  /* Translucent gray background */
  color: white;
  /* White text color for better visibility */
  padding: 5px 0;
  /* Padding for some space inside the caption */
  text-align: center;
  /* Center-align the text */
  font-size: 14px;
}

.image-view.selected {
  border-color: #FFA07A;
  ;
  /* Light orange border for selected image */
  border-width: 4px;
}

.review-compare p {
  color: #333;
  margin-top: -10px;
  margin-bottom: -5px;
  /* Reduced bottom margin for closer line spacing */
}

.anonymous {
  margin-bottom: 0px;
}

.send-vote {
  display: block;
  width: 250px;
  margin: 0px auto;
  padding: 15px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 22px;
  cursor: pointer;
}

.send-vote:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

@keyframes loadAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.send-vote.loading {
  background: linear-gradient(270deg, sage, lightgray);
  background-size: 200% 100%;
  animation: loadAnimation 2s ease-in-out;
  cursor: wait;
}

.send-vote.sent {
  background-color: sage !important;
  cursor: default;
}


/* Add additional styles as needed */