
yar.App {
  text-align: center;
}

/* The body class provides formatting of main content (between Navbar and Footer) */
.body {
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  width: 100%;
}

/* The .body class provides formatting of the background wallpaper, which is set up in the App.jsx file.                              */
/* Note:  the reason for formatting the .body and .background classes like this, instead of just using "background-attachment: fixed" */
/* is to prevent the background from scrolling in the mobile view. While it looks good in Chrome mobile view (on laptop), on an       */
/* actual mobile device, it is not working correctly; thus this hack. */
.background {
  /* background-image: url("@assets/images/WhiteFlorTile.png"); */
  background-image: url("@assets/images/off-white-color-solid-background-1920x1080.png");
  background-repeat: repeat;
  position: fixed;
  background-position-x: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

nav button {
  background: none !important;
  color: inherit;
  border: none;
  font: inherit;
  /*border is optional*/
  border-bottom: 1px solid #444;
  cursor: pointer;
}

.mainNavbarButton {
  color: #ffd54f !important;
}

.active .mainNavbarButton {
  color: #ffb300 !important;
}

button.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  /* margin: 0;
  padding: 0; */
  text-decoration: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-face-lobster {
  font-family: "LobsterRegular";
}

.hero-wrapper {
  flex-direction: column;
  /* position: relative; */
}

.bg-composed-wrapper {
  /* position: relative; */
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  /* display: flex; */
  /* align-items: start; */
}

.bg-composed-filter-rm {
  filter: grayscale(100%);
}

.bg-composed-wrapper--image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.opacity-9 {
  opacity: 0.9 !important;
}

/* Snackbar formatting (for footer, contact page) */
.snackbar {
  /* position: sticky !important; */
  position: absolute;
  bottom: 20%;
   /* left: auto !important; 
   right: auto !important;  
  transform: inherit !important; */
}
