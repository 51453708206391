#app { z-index: 1000 }

/* .sm-background {
  background: linear-gradient(to bottom, #ebb14c, transparent),
    linear-gradient(to top, #313131, transparent);
  background-blend-mode: screen;
} */

.sm-background {
  background: linear-gradient(to left, #ebb14c, transparent),
    linear-gradient(to right, #313131, transparent);
  background-blend-mode: screen;
}

/* Social Media formatting */
.text-facebook,
.fa-facebook:hover {
  color: #3b5999 !important;
}

.text-twitter,
.fa-twitter:hover {
  color: #1da1f2 !important;
}

.text-instagram,
.fa-instagram:hover {
  color: #e4405f !important;
}

.text-pinterest,
.fa-pinterest:hover {
  color: #bd081c !important;
}

.fa-tiktok:hover {
  color: black !important;
  background: linear-gradient(to right, #00f2ea, transparent),
    linear-gradient(to left, #ff0050, transparent);
  background-blend-mode: screen;
}

.text-linkedin,
.fa-linkedin:hover {
  color: #2867b2 !important;
}

.text-youtube,
.fa-youtube:hover {
  color: #ff0000 !important;
}

/* .text-google,
.fa-google:hover {
  color: #dd4b39 !important;
} */
