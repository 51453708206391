body {
  background-color: rgb(250, 249, 247);
}

.tangerine-insight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: #888; /* Lighter gray color */
}

.tangerine-insight span {
  font-size: 14px;
  margin-right: 10px;
}

.tangerine-insight .logo {
  height: calc(1.5 * 14px); /* 150% of the font size */
}

.review-ask {
  padding: 20px;
  margin: 20px auto;
  max-width: 300px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.review-ask h1 {
  color: #333;
  font-size: 25px;
  margin-bottom: 10px;
}

.review-ask p {
  color: #666;
  margin-bottom: 20px;
}


.info-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* When the info box is displayed, prevent interaction with other elements */
.review-ask {
  pointer-events: none;
}

.review-ask * {
  pointer-events: auto;
}

.info-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
  /* Space out the link from the text */
}

.additional-info {
  color: #666;
  font-size: 11px;
  /* Smaller font size for less important information */
  margin-top: -15px;
  /* Adjust spacing based on design preference */
  margin-bottom: -5px;
}

.photo-placeholder {
  position: relative;
  /* Needed to position the caption absolutely within this container */
  display: block;
  width: 250px;
  height: 250px;
  /* Adjust the height as needed */
  background-color:rgb(250, 249, 247); /* Match the webpage's background color */
  margin-bottom: 5px;
  border-radius: 8px;
}

.photo-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.image-caption {
  position: absolute;
  bottom: 25%;
  /* Position the caption 25% up from the bottom */
  width: 100%;
  /* Make the caption span the full width of the image-view */
  background-color: rgba(0, 0, 0, 0.5);
  /* Translucent gray background */
  color: white;
  /* White text color for better visibility */
  padding: 5px 0;
  /* Padding for some space inside the caption */
  text-align: center;
  /* Center-align the text */
  font-size: 14px;
}


.button-container {
  margin-bottom: 20px;
  display: flex;
  /* Align buttons horizontally */
  justify-content: center;
  /* Center buttons in the container */
}

.button {
  padding: 10px 30px;
  margin: 0 10px;
  /* Provide spacing between buttons */
  border: 2px solid transparent;
  /* Add a transparent border for layout consistency */
  border-radius: 22px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  /* Remove default focus outline */
}

.button.selected {
  border-color: #ffa500;
  /* Light orange border for the selected button */
  border-width: 4px;
  /* Double the border width */
}

.no {
  background-color: red;
}

.yes {
  background-color: green;
}

.send-vote {
  display: block;
  width: 100%;
  padding: 15px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 22px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  cursor: pointer;
}

.send-vote:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

@keyframes loadAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.send-vote.loading {
  background: linear-gradient(270deg, sage, lightgray);
  background-size: 200% 100%;
  animation: loadAnimation 2s ease-in-out;
  cursor: wait;
}

.send-vote.sent {
  background-color: sage !important;
  cursor: default;
}
